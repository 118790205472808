import _path from "path";
import _findMade from "./find-made.js";
import _mkdirpManual from "./mkdirp-manual.js";
var exports = {};
const {
  dirname
} = _path;
const {
  findMade,
  findMadeSync
} = _findMade;
const {
  mkdirpManual,
  mkdirpManualSync
} = _mkdirpManual;

const mkdirpNative = (path, opts) => {
  opts.recursive = true;
  const parent = dirname(path);
  if (parent === path) return opts.mkdirAsync(path, opts);
  return findMade(opts, path).then(made => opts.mkdirAsync(path, opts).then(() => made).catch(er => {
    if (er.code === "ENOENT") return mkdirpManual(path, opts);else throw er;
  }));
};

const mkdirpNativeSync = (path, opts) => {
  opts.recursive = true;
  const parent = dirname(path);
  if (parent === path) return opts.mkdirSync(path, opts);
  const made = findMadeSync(opts, path);

  try {
    opts.mkdirSync(path, opts);
    return made;
  } catch (er) {
    if (er.code === "ENOENT") return mkdirpManualSync(path, opts);else throw er;
  }
};

exports = {
  mkdirpNative,
  mkdirpNativeSync
};
export default exports;