import _util from "util";
import _fs from "fs";
var exports = {};
const {
  promisify
} = _util;
const fs = _fs;

const optsArg = opts => {
  if (!opts) opts = {
    mode: 511,
    fs
  };else if (typeof opts === "object") opts = {
    mode: 511,
    fs,
    ...opts
  };else if (typeof opts === "number") opts = {
    mode: opts,
    fs
  };else if (typeof opts === "string") opts = {
    mode: parseInt(opts, 8),
    fs
  };else throw new TypeError("invalid options argument");
  opts.mkdir = opts.mkdir || opts.fs.mkdir || fs.mkdir;
  opts.mkdirAsync = promisify(opts.mkdir);
  opts.stat = opts.stat || opts.fs.stat || fs.stat;
  opts.statAsync = promisify(opts.stat);
  opts.statSync = opts.statSync || opts.fs.statSync || fs.statSync;
  opts.mkdirSync = opts.mkdirSync || opts.fs.mkdirSync || fs.mkdirSync;
  return opts;
};

exports = optsArg;
export default exports;