import _path from "path";
var exports = {};
const {
  dirname
} = _path;

const mkdirpManual = (path, opts, made) => {
  opts.recursive = false;
  const parent = dirname(path);

  if (parent === path) {
    return opts.mkdirAsync(path, opts).catch(er => {
      // swallowed by recursive implementation on posix systems
      // any other error is a failure
      if (er.code !== "EISDIR") throw er;
    });
  }

  return opts.mkdirAsync(path, opts).then(() => made || path, er => {
    if (er.code === "ENOENT") return mkdirpManual(parent, opts).then(made => mkdirpManual(path, opts, made));
    if (er.code !== "EEXIST" && er.code !== "EROFS") throw er;
    return opts.statAsync(path).then(st => {
      if (st.isDirectory()) return made;else throw er;
    }, () => {
      throw er;
    });
  });
};

const mkdirpManualSync = (path, opts, made) => {
  const parent = dirname(path);
  opts.recursive = false;

  if (parent === path) {
    try {
      return opts.mkdirSync(path, opts);
    } catch (er) {
      // swallowed by recursive implementation on posix systems
      // any other error is a failure
      if (er.code !== "EISDIR") throw er;else return;
    }
  }

  try {
    opts.mkdirSync(path, opts);
    return made || path;
  } catch (er) {
    if (er.code === "ENOENT") return mkdirpManualSync(path, opts, mkdirpManualSync(parent, opts, made));
    if (er.code !== "EEXIST" && er.code !== "EROFS") throw er;

    try {
      if (!opts.statSync(path).isDirectory()) throw er;
    } catch (_) {
      throw er;
    }
  }
};

exports = {
  mkdirpManual,
  mkdirpManualSync
};
export default exports;