import _optsArg from "./lib/opts-arg.js";
import _pathArg from "./lib/path-arg.js";
import _mkdirpNative from "./lib/mkdirp-native.js";
import _mkdirpManual from "./lib/mkdirp-manual.js";
import _useNative from "./lib/use-native.js";
var exports = {};
const optsArg = _optsArg;
const pathArg = _pathArg;
const {
  mkdirpNative,
  mkdirpNativeSync
} = _mkdirpNative;
const {
  mkdirpManual,
  mkdirpManualSync
} = _mkdirpManual;
const {
  useNative,
  useNativeSync
} = _useNative;

const mkdirp = (path, opts) => {
  path = pathArg(path);
  opts = optsArg(opts);
  return useNative(opts) ? mkdirpNative(path, opts) : mkdirpManual(path, opts);
};

const mkdirpSync = (path, opts) => {
  path = pathArg(path);
  opts = optsArg(opts);
  return useNativeSync(opts) ? mkdirpNativeSync(path, opts) : mkdirpManualSync(path, opts);
};

mkdirp.sync = mkdirpSync;

mkdirp.native = (path, opts) => mkdirpNative(pathArg(path), optsArg(opts));

mkdirp.manual = (path, opts) => mkdirpManual(pathArg(path), optsArg(opts));

mkdirp.nativeSync = (path, opts) => mkdirpNativeSync(pathArg(path), optsArg(opts));

mkdirp.manualSync = (path, opts) => mkdirpManualSync(pathArg(path), optsArg(opts));

exports = mkdirp;
export default exports;