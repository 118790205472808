import _path from "path";
var exports = {};
const {
  dirname
} = _path;

const findMade = (opts, parent, path = undefined) => {
  // we never want the 'made' return value to be a root directory
  if (path === parent) return Promise.resolve();
  return opts.statAsync(parent).then(st => st.isDirectory() ? path : undefined, // will fail later
  er => er.code === "ENOENT" ? findMade(opts, dirname(parent), parent) : undefined);
};

const findMadeSync = (opts, parent, path = undefined) => {
  if (path === parent) return undefined;

  try {
    return opts.statSync(parent).isDirectory() ? path : undefined;
  } catch (er) {
    return er.code === "ENOENT" ? findMadeSync(opts, dirname(parent), parent) : undefined;
  }
};

exports = {
  findMade,
  findMadeSync
};
export default exports;